import React from "react"
import Layout from "../components/layout"
import sliderImage from "../images/glowna/01.jpg"
import sliderImageMobile from "../images/m_01.jpg"
import sliderImageSmall from "../images/m_01b.jpg"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"
import ScrollBlock from "../components/scrollBlock"
import { InView } from "react-intersection-observer"
import LinkBox from "../components/LinkBox"
import GoogleMap from "../components/GoogleMap";
import Banner from "../components/Banner"
import SubBanner from "../components/contact/SubBanner"
import ContactInfo from "../components/contact/ContactInfo"

const ContactPage = (props) => {
  const { t, i18n } = useTranslation();
  if (i18n.language !== "pl") i18n.changeLanguage("pl")
  return (
    <Layout props={props}>
      <div className="slider relative bg-black">
        <Banner
          sliderImage={sliderImage}
          sliderImageMobile={sliderImageMobile}
          sliderImageSmall={sliderImageSmall}
          title01={t("contact.fast")}
          title02={t("general.contact")}
          subtitle={t("contact.subtitle")}
          id="banner"
          link1={t("general.fleetHref")}
          link2={t("general.contactHref")}
        ></Banner>
        <ScrollBlock></ScrollBlock>
      </div>
      <SubBanner />
      <ContactInfo />
      <section className="map">
        <GoogleMap />
      </section>
      <section>
        <div className="flex flex-wrap">
          <div className="tb:w-1/2 w-full">
            <LinkBox
              link="/o-nas/"
              cssClass="about"
              text={t("general.about")}
            ></LinkBox>
          </div>
          <div className="tb:w-1/2 w-full">
            <LinkBox
              link="/flota/"
              cssClass="fleet"
              text={t("general.fleet")}
            ></LinkBox>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage;
